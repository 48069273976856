import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip, Modal} from 'react-bootstrap';
import { useNavigate  } from "react-router-dom";
import axios from "axios";
import Header from '../components/Header';
import Back from '../components/back';
import UserService from '../services/UserService';
import FileSaver from 'file-saver';
import ClientChat from '../components/ClientChat';
import {OutTable, ExcelRenderer} from 'react-excel-renderer';

const serverConfig = require("../config/server.js");

const ApplicationDetails = () => {

  const navigate = useNavigate();

  const [mToken, setMToken] = useState(sessionStorage.getItem('mToken'));

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
  }

  var url = window.location.href;
  var applicationID = url.substring(url.indexOf('?')+1);
  console.log('applicationID', applicationID);

  const[userPrivilegedMessaging, setUserPrivilegedMessaging] = useState(false);
  const[userPrivilegedReadApplication, setUserPrivilegedReadApplication] = useState(false);
  const[userPrivilegedReadApplicationHistory, setUserPrivilegedReadApplicationHistory] = useState(false);
  const[userPrivilegedViewKyc, setUserPrivilegedViewKyc] = useState(false);
  const[userPrivilegedDownload, setUserPrivilegedDownload] = useState(false);
  const[userPrivilegedReadTPCommunicationLog, setUserPrivilegedReadTPCommunicationLog] = useState(false);
  const[userPrivilegedSendEngagementAgreement, setUserPrivilegedSendEngagementAgreement] = useState(false);
  const[userPrivilegedOnboardingApprove, setUserPrivilegedOnboardingApprove] = useState(false);
  const[userPrivilegedReadPayroll, setUserPrivilegedReadPayroll] = useState(false);
  const[userPrivilegedReadPseudonymizedPayroll, setUserPrivilegedReadPseudonymizedPayroll] = useState(false);
  const[userPrivilegedWritePseudonymizedPayroll, setUserPrivilegedWritePseudonymizedPayroll] = useState(false);
  const[userPrivilegedReadProcessedPayroll, setUserPrivilegedReadProcessedPayroll] = useState(false);
  const[userPrivilegedWriteProcessedPayroll, setUserPrivilegedWriteProcessedPayroll] = useState(false);
  const[userPrivilegedReadSupportPayroll, setUserPrivilegedReadSupportPayroll] = useState(false);
  const[userPrivilegedWriteSupportPayroll, setUserPrivilegedWriteSupportPayroll] = useState(false);
  const[userPrivilegedUpdateDocument, setUserPrivilegedUpdateDocument] = useState(false);
  const[userPrivilegedPIIInfo, setUserPrivilegedPIIInfo] = useState(false);

  const[file, setFile] = useState('');
  const[excelFile, setExcelFile] = useState(null);
  const[DLBase64, setDLBase64] = useState(null);

  const [appData, setAppData] = useState('');
  const [appDataTimestamp, setAppDataTimestamp] = useState('');
  const [appDataStatus, setAppDataStatus] = useState(null);
  const [dataName, setDataName] = useState('');
  const [dataEmailid, setDataEmailid] = useState('');
  const [dataPhoneNum, setDataPhoneNum] = useState('');
  const [dataCovidImpactedQuarters, setDataCovidImpactedQuarters] = useState([]);
  const [dataReducedServiceHoursReasons, setDataReducedServiceHoursReasons] = useState([]);
  const [dataClaimedCredits, setDataClaimedCredits] = useState([]);
  const [disruptionData, setDisruptionData] = useState([]);
  const [locationDisruptionData, setLocationDisruptionData] = useState([]);
  const [staffingDisruptionData, setStaffingDisruptionData] = useState({});
  const [SoFSignedByCustomer, setSoFSignedByCustomer] = useState([]);
  const [beneficialOwnerInformation, setBeneficialOwnerInformation] = useState([]);
  const [engagementSignatureStatus, setEngagementSignatureStatus] = useState([]);

  const[tpCommunicationLog, setTPCommunicationLog] = useState([]);
  const [auditData, setAuditData] = useState([]);

  const [denyMarkCompleted, setDenyMarkCompleted] = useState(false);

  //const [isLoading, setIsLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [showFileModal, setShowFileModal] = useState(false);

  const [showReportModal, setShowReportModal] = useState(false);
  const [reportData, setReportData] = useState("");

  const [sofInputs, setSofInputs] = useState({});
  const [sofOption, setSofOption] = useState("");

  const[timeframeStartDate,setTimeframeStartDate] = useState(null);
  const[timeframeEndDate,setTimeframeEndDate] = useState(null);

  const getAppData = () => {
    axios.post(serverConfig.api_base_url + "get_app_data",{token:mToken, app_id:applicationID})
    .then(response=>{
      setIsLoading(false);
      console.log(mToken);
      console.log("get_app_data response:", response);
      console.log(response.data.status);
      if(response.data.status === 200 ){
        if(response.data.output_data !== null){
          let data = JSON.parse(response.data.output_data);
          console.log("get_app_data response:", data);
          setDataName(data.name);
          setDataEmailid(data.email_id);
          setDataPhoneNum(data.phone_number);
          setAppData(JSON.parse(JSON.stringify(data.app_data).replace(/&apos;/g,"'")));
          data.app_data.CovidImpactedQuarters && setDataCovidImpactedQuarters(JSON.parse(data.app_data.CovidImpactedQuarters));
          data.app_data.ReducedServiceHoursReasons && setDataReducedServiceHoursReasons(JSON.parse(data.app_data.ReducedServiceHoursReasons));
          data.app_data.ClaimedCredits && setDataClaimedCredits(JSON.parse(data.app_data.ClaimedCredits));
          //console.log(data.app_data.StatewiseDisruptionData && JSON.parse(data.app_data.StatewiseDisruptionData));
          data.app_data.StatewiseDisruptionData && setDisruptionData(JSON.parse(data.app_data.StatewiseDisruptionData));
          data.app_data.LocationwiseDisruptionData && setLocationDisruptionData(JSON.parse(data.app_data.LocationwiseDisruptionData));
          data.app_data.StaffingDisruptionData && setStaffingDisruptionData(JSON.parse(data.app_data.StaffingDisruptionData));
          data.app_data.StatementOfFactSignedByCustomer && setSoFSignedByCustomer(JSON.parse(data.app_data.StatementOfFactSignedByCustomer));
          data.app_data.BeneficialOwnerInformation && setBeneficialOwnerInformation(JSON.parse(data.app_data.BeneficialOwnerInformation));
          console.log(data.app_data.StatementOfFactFile);
          console.log(data.app_data.CdCompanyName);
          if(data.app_data.StatementOfFactFile){
            setSofOption('sof-upload');
          } else if(data.app_data.CdCompanyName){
            setSofOption('sof-template');
          }
          //console.log(data.app_data.StatementOfFactSignedByCustomer && JSON.parse(data.app_data.StatementOfFactSignedByCustomer));
          data.app_data.EngagementSignatureStatus && setEngagementSignatureStatus(JSON.parse(data.app_data.EngagementSignatureStatus));
          setAppDataTimestamp(data.app_data_timestamp);
          setAppDataStatus(data.app_data_status);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }
  
  const getToken = () => {
    console.log(sessionStorage.getItem('mToken'));
    //let emailid = UserService.getUsername();
    //console.log('email', emailid);
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "get_user_token",{jwt:jwt})
    .then(response=>{
      console.log('get_user_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('mToken', response.data.token);
        setMToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(sessionStorage.getItem('mToken'));
    if(sessionStorage.getItem('mToken') === null){
      getToken();
    }
  }, []);

  useEffect(() => {
    if(mToken !== null && mToken !== undefined){
      //DriversLicense base64
      axios.post(serverConfig.api_base_url + "get_file",{token:mToken, entity:'DriversLicense', application_id:applicationID})
      .then(response=>{
        if (response.data.status === 200) {
          setDLBase64(response.data.data);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      } )
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - update_application */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'update_application'})
      .then(response=>{
        console.log("check_user_privilege update_application response:", response);
        if(response.data.status === 200){
          console.log("check_user_privilege update_application response:", response.data.is_privileged);
          let denyMarkCompleted = response.data.is_privileged !== 0;
          setDenyMarkCompleted(denyMarkCompleted);
          console.log("denyMarkCompleted=" + denyMarkCompleted);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
      
      /* check_user_privilege - read_application */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'read_application'})
      .then(response=>{
        console.log("read_application privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedReadApplication(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedReadApplication(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - read_application_history */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'read_application_history'})
      .then(response=>{
        console.log("read_application_history privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedReadApplicationHistory(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedReadApplicationHistory(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - messaging */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'messaging'})
      .then(response=>{
        console.log("messaging privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedMessaging(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedMessaging(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - view_tp_communication_log */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'view_tp_communication_log'})
      .then(response=>{
        console.log("view_tp_communication_log privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedReadTPCommunicationLog(true);
            
            axios.post(serverConfig.api_base_url + "get_message_list",{token:mToken, application_id:applicationID})
            .then(response=>{
              console.log('get_message_list response', response);
              if(response.data.status === 200){
                if(response.data.items !== null){
                  let msgArray = JSON.parse(response.data.items);
                  console.log('get_message_list success', msgArray);
                  setTPCommunicationLog(msgArray);
                }
              }
            })
            .catch(err=>console.log("error is",err));
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedReadTPCommunicationLog(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - view_kyc_details */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'view_kyc_details'})
      .then(response=>{
        console.log("view_kyc_details privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedViewKyc(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedViewKyc(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - send_engagement_agreement */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'send_engagement_agreement'})
      .then(response=>{
        console.log("send_engagement_agreement privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedSendEngagementAgreement(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedSendEngagementAgreement(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - onboarding_approve */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'onboarding_approve'})
      .then(response=>{
        console.log("onboarding_approve privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedOnboardingApprove(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedOnboardingApprove(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - download_all */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'download_all'})
      .then(response=>{
        console.log("download_all privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedDownload(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedDownload(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - read_payroll_data */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'read_payroll_data'})
      .then(response=>{
        console.log("read_payroll_data privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedReadPayroll(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedReadPayroll(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - read_pseudonymized_payroll_data */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'read_pseudonymized_payroll_data'})
      .then(response=>{
        console.log("read_pseudonymized_payroll_data privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedReadPseudonymizedPayroll(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedReadPseudonymizedPayroll(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - write_pseudonymized_payroll_data */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'write_pseudonymized_payroll_data'})
      .then(response=>{
        console.log("write_pseudonymized_payroll_data privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedWritePseudonymizedPayroll(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedWritePseudonymizedPayroll(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - read_processed_payroll_data */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'read_processed_payroll_data'})
      .then(response=>{
        console.log("read_processed_payroll_data privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedReadProcessedPayroll(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedReadProcessedPayroll(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - write_processed_payroll_data */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'write_processed_payroll_data'})
      .then(response=>{
        console.log("write_processed_payroll_data privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedWriteProcessedPayroll(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedWriteProcessedPayroll(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - read_support_payroll_data */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'read_support_payroll_data'})
      .then(response=>{
        console.log("read_support_payroll_data privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedReadSupportPayroll(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedReadSupportPayroll(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - write_support_payroll_data */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'write_support_payroll_data'})
      .then(response=>{
        console.log("write_support_payroll_data privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedWriteSupportPayroll(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedWriteSupportPayroll(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - update_document */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'update_document'})
      .then(response=>{
        console.log("update_document privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedUpdateDocument(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedUpdateDocument(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - read_pii_info */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'read_pii_info'})
      .then(response=>{
        console.log("read_pii_info privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedPIIInfo(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedPIIInfo(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* get_app_data */
      console.log('mToken', mToken);
      getAppData();
      console.log(appData);

      axios.post(serverConfig.api_base_url + "get_app_history",{token:mToken, app_id:applicationID})
      .then(response=>{
        console.log('get_app_history response', response);
        if (response.data.status === 200 && response.data.items !== null) {
          let data = JSON.parse(response.data.items);
          console.log(data);
          setAuditData(data);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      } )
      .catch(err=>console.log("error is",err));
    }
  }, [mToken]);

  const showFile = (entityname) => {
    setIsLoading(true);
    document.body.classList.add('loader-show');
    console.log('get file');
    axios.post(serverConfig.api_base_url + "get_file",{token:mToken, entity:entityname, application_id:applicationID})
    .then(response=>{
      if (response.data.status === 200) {
        setIsLoading(false);
        document.body.classList.remove('loader-show');
        console.log('loaded');

        if((response.data.data).startsWith("data:application/vnd") || (response.data.data).startsWith("data:text/csv")){
          console.log(response.data.data);

          var arr = response.data.data.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[arr.length - 1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          while(n--){
              u8arr[n] = bstr.charCodeAt(n);
          }
          var file = new File([u8arr], appData[entityname], {type:mime});
          console.log(file);

          ExcelRenderer(file, (err, resp) => {
            const columns = [{name: '', key: 0}];
            resp.cols.map(item => {
                columns.push({name: item.name, key: item.key + 1});
                return null;
            })
            if(err){
              console.log(err);            
            }
            else{
              //let newCol = [{name: '', key: ''},...resp.cols];
              console.log(resp);
              //console.log(newCol);
              setExcelFile({rows:resp.rows, cols:columns});
              setShowFileModal(true);
            }
          }); 
          //alert('The File will now be downloaded into the folder that saves all your "Downloads".');
          //FileSaver.saveAs(response.data.data, appData[entityname]);
          
        } else {
          console.log(response.data.data);
          setFile(response.data.data);
          setShowFileModal(true);
        }
        
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    } )
    .catch(err=>console.log("error is",err));
  }

  const downloadFile = (entityname) => {
    setIsLoading(true);
    document.body.classList.add('loader-show');
    axios.post(serverConfig.api_base_url + "get_file",{token:mToken, entity:entityname, application_id:applicationID})
    .then(response=>{
      if (response.data.status === 200) {
        setIsLoading(false);
        document.body.classList.remove('loader-show');
        console.log('loaded');

        alert('The File will now be downloaded into the folder that saves all your "Downloads".');
        FileSaver.saveAs(response.data.data, appData[entityname]);
        
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    } )
    .catch(err=>console.log("error is",err));
  }

  const showReport = (entityname) => {
    console.log(appData[entityname]);
    var obj = JSON.parse(appData[entityname]);
    setReportData(<pre>{JSON.stringify(obj, null, 4)}</pre>);
    setShowReportModal(true);
  }

  const getIDMSubfields = (subreport) => {
    return Object.keys(subreport).map(key =>
      //"<tr><th>"+key+"</th><td>"+subreport[key]["@PSGFDisplayValue"]+"</td></tr>");
      <tr><th>{key}</th><td>{subreport[key]["@PSGFDisplayValue"]}</td></tr>);
  }

  const getIDMissionFields = (reportData) => {
    var html = <table><tbody> {getIDMSubfields(reportData.ID_Image_Processing)} {getIDMSubfields(reportData.Additional_Data)} </tbody></table>;
    return html;
  }

  const showDLReport = (entityname) => {
    console.log(appData[entityname]);
    var obj = JSON.parse(appData[entityname]);
    console.log(obj.FormDetails);
    setReportData(getIDMissionFields(obj.FormDetails.Identity_Validation_and_Face_Matching.IDmission_Image_Processing));
    setShowReportModal(true);
  }

  const markAsCompleted = () => {
    axios.post(serverConfig.api_base_url + "set_completed",{token:mToken, app_id:applicationID})
    .then(response=>{
      console.log('set_completed response', response);
      if (response.data.status === 200) {
        navigate('/');
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    } )
    .catch(err=>console.log("error is",err));
  }

  const downloadAll = () => {
    console.log('calling download all');
    setIsLoading(true);
    axios.post(serverConfig.api_base_url + "get_files_zip",{token:mToken, application_id:applicationID}, {
      responseType: 'arraybuffer'
    })
    .then(response=>{
      console.log('get_files_zip response', response);
      var blob = new Blob([response.data], {type: "octet/stream"});
      console.log(blob.size);
      alert('Files will now be downloaded into the folder that saves all your "Downloads".');
      FileSaver.saveAs(blob, appData.EINNumber+'.zip');
      setIsLoading(false);
    } )
    .catch(err=>console.log("error is",err));
  }

  const [showSoF, setShowSoF] = useState(false);
  const handleCloseSoF = () => {
    setSofInputs({});
    setShowSoF(false);
  }
  const handleShowSoF = () => {
    setSofInputs({
      CdCompanyName: appData.CdCompanyName,
      CdFEINNumber: appData.CdFEINNumber,
      CdIndustry: appData.CdIndustry,
      CdLocation: appData.CdLocation,
      CdAvgEmp2019: appData.CdAvgEmp2019,
      CdPPP1Period: appData.CdPPP1Period,
      CdPPP2Period: appData.CdPPP2Period,
      CdOwnersNameAndPercentageOwnership: appData.CdOwnersNameAndPercentageOwnership,
      CdOwnersRelatedAndRelationship: appData.CdOwnersRelatedAndRelationship,
      CdEmployeeNamesRelatedOwnerRelationship: appData.CdEmployeeNamesRelatedOwnerRelationship,
      CdGrossReceipt2019Q1: appData.CdGrossReceipt2019Q1,
      CdGrossReceipt2019Q2: appData.CdGrossReceipt2019Q2,
      CdGrossReceipt2019Q3: appData.CdGrossReceipt2019Q3,
      CdGrossReceipt2019Q4: appData.CdGrossReceipt2019Q4,
      CdGrossReceipt2020Q1: appData.CdGrossReceipt2020Q1,
      CdGrossReceipt2020Q2: appData.CdGrossReceipt2020Q2,
      CdGrossReceipt2020Q3: appData.CdGrossReceipt2020Q3,
      CdGrossReceipt2020Q4: appData.CdGrossReceipt2020Q4,
      CdGrossReceipt2021Q1: appData.CdGrossReceipt2021Q1,
      CdGrossReceipt2021Q2: appData.CdGrossReceipt2021Q2,
      CdGrossReceipt2021Q3: appData.CdGrossReceipt2021Q3,
      CdGrossReceipt2021Q4: appData.CdGrossReceipt2021Q4,
      TpEligibilityTimeframe: appData.TpEligibilityTimeframe,
      TpEligibilityNotesGR: appData.TpEligibilityNotesGR && appData.TpEligibilityNotesGR.replace(/<br\s*\/?>/gi,'\n').replace(/&apos;/g,"'"),
      TpEligibilityNotesSuspension: appData.TpEligibilityNotesSuspension && appData.TpEligibilityNotesSuspension.replace(/<br\s*\/?>/gi,'\n').replace(/&apos;/g,"'"),
      TpEmployerProvidedFacts: appData.TpEmployerProvidedFacts && appData.TpEmployerProvidedFacts.replace(/<br\s*\/?>/gi,'\n').replace(/&apos;/g,"'"),
      TpAdditionalComments: appData.TpAdditionalComments && appData.TpAdditionalComments.replace(/<br\s*\/?>/gi,'\n').replace(/&apos;/g,"'")
    });
    setShowSoF(true);
  }

  const handleUpdateCustomerDetails = () => {
    console.log(sofInputs);
    console.log(appData.TpEmployerProvidedFacts);
    console.log(sofInputs.TpEmployerProvidedFacts);
    let location = '';
    if(appData.PermanentAddressLine1){
      location += appData.PermanentAddressLine1 +', ';
      if(appData.PermanentAddressLine2){
        location += appData.PermanentAddressLine2 +', ';
      }
      location += appData.PermanentState +', ';
      location += appData.PermanentCity +', ';
      location += appData.PermanentPostalCode;
    }
    let avgEmp2019 = Number(appData.FTE2019) + Number(appData.PTE2019);

    let ppp1period, ppp2period;
    if(appData.PPP1StartDate && appData.PPP1EndDate){
      ppp1period = appData.PPP1StartDate +' to '+ appData.PPP1EndDate;
    }
    if(appData.PPP2StartDate && appData.PPP2EndDate){
      ppp2period = appData.PPP2StartDate +' to '+ appData.PPP2EndDate;
    }

    setSofInputs({
      ...sofInputs,
      CdCompanyName: appData.CompanyName && appData.CompanyName.replace(/'/g, '&apos;'),
      CdFEINNumber: appData.EINNumber && appData.EINNumber.replace(/'/g, '&apos;'),
      CdIndustry: appData.Industry,
      CdLocation: location && location.replace(/'/g, '&apos;'),
      CdAvgEmp2019: avgEmp2019,
      CdPPP1Period: ppp1period,
      CdPPP2Period: ppp2period,
      CdOwnersNameAndPercentageOwnership: appData.OwnersNameAndPercentageOwnership && appData.OwnersNameAndPercentageOwnership.replace(/'/g, '&apos;'),
      CdOwnersRelatedAndRelationship: appData.OwnersRelatedAndRelationship && appData.OwnersRelatedAndRelationship.replace(/'/g, '&apos;'),
      CdEmployeeNamesRelatedOwnerRelationship: appData.EmployeeNamesRelatedOwnerRelationship && appData.EmployeeNamesRelatedOwnerRelationship.replace(/'/g, '&apos;'),
      CdGrossReceipt2019Q1: appData.GrossReceipt2019Q1,
      CdGrossReceipt2019Q2: appData.GrossReceipt2019Q2,
      CdGrossReceipt2019Q3: appData.GrossReceipt2019Q3,
      CdGrossReceipt2019Q4: appData.GrossReceipt2019Q4,
      CdGrossReceipt2020Q1: appData.GrossReceipt2020Q1,
      CdGrossReceipt2020Q2: appData.GrossReceipt2020Q2,
      CdGrossReceipt2020Q3: appData.GrossReceipt2020Q3,
      CdGrossReceipt2020Q4: appData.GrossReceipt2020Q4,
      CdGrossReceipt2021Q1: appData.GrossReceipt2021Q1,
      CdGrossReceipt2021Q2: appData.GrossReceipt2021Q2,
      CdGrossReceipt2021Q3: appData.GrossReceipt2021Q3,
      CdGrossReceipt2021Q4: appData.GrossReceipt2021Q4,
      TpEligibilityNotesGR: appData.TpEligibilityNotesGR,
      TpEligibilityNotesSuspension: appData.TpEligibilityNotesSuspension,
      TpEmployerProvidedFacts: appData.TpEmployerProvidedFacts,
      TpAdditionalComments: appData.TpAdditionalComments
    });
  }

  const handleChange = (event) => {
    //console.log('changing', event, event.target.value);
    console.log(event.target.type);
    const name = event.target.name;
    let value = event.target.value;

    const newData={...sofInputs};

    if(event.target.type === 'textarea'){
      if(value !== ''){
        value = value.replace(/(\r\n|\r|\n)/g, '<br />').replace(/'/g, '&apos;');
      }
    }

    if(name === 'TimeframeStartDate'){
      setTimeframeStartDate(value);
      newData['TpEligibilityTimeframe'] = value +' to '+ timeframeEndDate;
    } else if(name === 'TimeframeEndDate'){
      setTimeframeEndDate(value);
      newData['TpEligibilityTimeframe'] = timeframeStartDate +' to '+ value;
    } else {
      newData[name]=value;
    }

    console.log(newData);
    setSofInputs(newData);
    console.log('sofInputs', sofInputs);
  }

  const handleSaveSoFData = (e) => {
    e.preventDefault();
    console.log(sofInputs);
    var formdata = {token:mToken, application_id: applicationID, entity_group:'statement_of_fact', items:sofInputs};
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "set_data",formdata)
    .then(response=>{
      console.log('set_data response', response);
      if(response.data.status === 200) {
        getAppData();//refresh statement of fact status
        handleCloseSoF();
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }


  const handleUpload = (groupName, event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(name,value);
    var extension = value.split('.').pop();
    console.log(extension);

    let uploadFunc = () => {
      setIsLoading(true);
      console.log("File type matched");
      var file = document.querySelector('#'+event.target.id).files[0];
      console.log(file);

      if(file !== undefined) {
        let filename = file.name;
        var fileData;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          fileData = reader.result;
          console.log({token:mToken, application_id: applicationID, entity_group:groupName, entity:name, file_name: filename, file_data: fileData});
          axios.post(serverConfig.api_base_url + "set_file",{token:mToken, application_id: applicationID, entity_group:groupName, entity:name, file_name: filename, file_data: fileData})
          .then(response=>{
            console.log('set_file response', response);
            if(response.data.status === 200) {
              setIsLoading(false);
              console.log('success');
              getAppData();//update to new filename
            } else if(response.data.status === 301){
              handleLogout();
            } else {
              event.target.value = '';
              alert(response.data.message);
              setIsLoading(false);
            }
          })
          .catch(err=>console.log("error is",err));
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
      }
    }

    if(groupName === 'final_form_941x' || groupName === 'pseudonymized_payroll' || groupName === 'processed_payroll' || groupName === 'documents_from_tp_support'){
      if(extension=='xls' || extension=='xlsx' || extension=='csv' || extension=='pdf' || extension=='docx'){
        uploadFunc();
      } else {
        alert("Only xls, xlsx, csv, pdf and docx files are allowed");
        event.target.value = null;
      }
    } else if(groupName === 'engagement_agreement'){
      if(extension=='pdf'){
        uploadFunc();
      } else {
        alert("Only pdf file is allowed");
        event.target.value = null;
      }
    } else if(groupName === 'statement_of_fact'){
      if(extension=='pdf'){
        uploadFunc();
      } else {
        alert("Only pdf file is allowed");
        event.target.value = null;
      }
    }
  }

  const handleSetData = (group_name, entity_name, status, e) => {
    e.preventDefault();
    var formdata = {token:mToken, application_id: applicationID, entity_group:group_name, items:{[entity_name]: status}};
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "set_data",formdata)
    .then(response=>{
      console.log('set_data response', response);
      if(response.data.status === 200) {
        getAppData();//refresh status
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleSetEngagementAgreementTemplateName = (e) => {
    console.log(e.target.value);
    if(e.target.value !== ''){
      var formdata = {token:mToken, application_id: applicationID, entity_group:'engagement_agreement', items:{EngagementAgreementTemplateName: e.target.value}};
      console.log(formdata);
      axios.post(serverConfig.api_base_url + "set_data",formdata)
      .then(response=>{
        console.log('set_data response', response);
        if(response.data.status === 200) {
          getAppData();//refresh statement of fact status
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }

  const handleSelectSofOption = (e) => {
    console.log(e.target.value);
    if(e.target.value === 'sof-upload'){
      setSofOption('sof-upload');
    } else if(e.target.value === 'sof-template'){
      setSofOption('sof-template');
    }
  }
  
  return (
    <>
      <Header />
      <main className='application-details'>
        {isLoading && (<div id="loading"></div>)}
        {userPrivilegedReadApplication && <>
          <section>
            <Container fluid>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='page-title'>
                  <Back />
                  <div>
                    <div>Applicaion ID: <b>{appData.ApplicationId}</b></div>
                    <h1 className='mb-0'>{appData.CompanyName}</h1>
                  </div>
                </div>
                {appData.Submitted ? <>&nbsp;</> : <div className='txt-red'><b>User submission pending!</b></div>}
                {/* {appDataStatus && <div className='txt-red'><b>{appDataStatus}</b></div>} */}
              </div>
            </Container>
          </section>
          {/* User Information */}
          <section className='bg-gray'>
            <Container fluid>
            <h6>User Information</h6>
              <Row className='mb-3'>
                <Col><span className='label'>Full Name:</span><br/><span>{appData.UserFirstName} {appData.UserMiddleName} {appData.UserLastName}</span></Col>
                <Col><span className='label'>Title:</span><br/><span>{appData.UserTitle}</span></Col>
                <Col>{userPrivilegedPIIInfo && <><span className='label'>Phone Number:</span><br/><span>{appData.UserPhoneNumber}</span></>}</Col>
                <Col>{userPrivilegedPIIInfo && <><span className='label'>Email address:</span><br/><span>{appData.UserEmail}</span></>}</Col>
              </Row>
              <Row>
                <Col><span className='label'>ID Verification:</span><br/><span>{appData.DriversLicense ? 'Completed' : 'Pending'}</span></Col>
                <Col><span className='label'>Is business owner:</span><br/><span>{appData.IsBusinessOwner}</span></Col>
                <Col xs={6}><span className='label'>Is authorized signer for the business:</span><br/><span>{appData.IsAuthSigner}</span></Col>
              </Row>
            </Container>
          </section>
          {/* Company Information */}
          <section className='bg-gray'>
            <Container fluid>
              <h6>Company Information</h6>
              <section>
                <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Business Details</b></div>
                <div className="sub-group">
                  <Row className='mb-3'>
                    {/* <Col md="3"><span className='label'>Company Description:</span> {appData.CompanyDescription}</Col> */}
                    <Col><span className='label'>NAICS Code:</span><br/>{appData.NaicsCode}</Col>
                    <Col><span className='label'>EIN Number:</span><br/>{appData.EINNumber}</Col>
                    <Col><span className='label'>DUNS:</span><br/>{appData.Duns}</Col>
                    <Col><span className='label'>Trade name or DBA:</span><br/>{appData.TradeNameDBA}</Col>
                  </Row>
                  <Row className='mb-3'>
                    {//<Col><span className='label'>Website URL:</span><br/>{appData.WebsiteURL}</Col>
                    }
                    <Col><span className='label'>Industry:</span><br/>{appData.Industry}</Col>
                    <Col><span className='label'>Payroll done by a PEO:</span><br/>{appData.PayrollByPEO}</Col>
                    <Col><span className='label'>Entity filling type:</span><br/>{appData.EntityFillingType}</Col>
                    <Col><span className='label'>Corp Code:</span><br/>{appData.CorpCode}</Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col><span className='label'>Calendar Year:</span><br/>{appData.CalendarYear}</Col>
                  </Row>
                  {appData.Industry === 'Banks / Credit Union / CDFI' ? <></> : <Row className='mb-3'>
                    {/* <Col><span className='label'>Approximate annual company revenue:</span><br/>{appData.AnnualCompanyRevenue}</Col> */}
                    {/* <Col><span className='label'> Business started after 15th Feb, 2020:</span><br/>{appData.IsNewBiz}</Col> */}
                    <Col><span className='label'>Business any of these? (Private or commercial golf course, Country club, Massage parlor, Hot tub facility, Suntan facility, Racetrack or other facility used for gambling, Retail store with the principal business of alcohol sales for consumption off premises, Business with the principal activity of farming)</span><br/>{appData.IsBusinessInList}</Col>
                  </Row>}
                  <section className='bdr-top'>
                    <Row>
                      <Col><span className='label'>Permanent Address</span><br/>
                        {appData.PermanentAddressLine1}, {appData.PermanentAddressLine2 && <>{appData.PermanentAddressLine2}, </>}
                        {appData.PermanentState}, {appData.PermanentCity}, {appData.PermanentPostalCode}
                      </Col>
                      <Col><span className='label'>Location Code</span><br/>
                        {appData.LocationCode}
                      </Col>
                      <Col>
                        {appData.MailingAddressLine1 && <>
                          <span className='label'>Mailing Address</span><br/>
                          {appData.MailingAddressLine1}, {appData.MailingAddressLine2 && <>{appData.MailingAddressLine2}, </>}
                          {appData.MailingState}, {appData.MailingCity}, {appData.MailingPostalCode}
                        </>}
                      </Col>
                    </Row>
                  </section>
                  <section className='bdr-top mt-3'>
                    <div><b>Entity Questionnaire</b></div>
                    <div><b>General Business Information</b></div>
                    <Row>
                      <Col><span className='label'>Entity is a Fund, Foundation, or Benefit Plan:</span><br/>{appData.IsEntityAnyOfTheFollowing}</Col>
                      <Col><span className='label'>Is the entity a Branch:</span><br/>{appData.IsEntityABranch}</Col>
                      <Col><span className='label'>Is the entity part of a government structure:</span><br/>{appData.IsEntityGovtPart}</Col>
                      <Col></Col>
                    </Row>
                    <table className='table table-bordered mt-3'>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Parent / Individual Owner Name</th>
                          <th>Ultimate Parent Company</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>Legal Name</th>
                          <td>{appData.EnitityOwnerLegalName}</td>
                          <td>{appData.ParentCompanyLegalName}</td>
                        </tr>
                        <tr>
                          <th>Alias (if applicable)</th>
                          <td>{appData.EnitityOwnerAlias}</td>
                          <td>{appData.ParentCompanyAlias}</td>
                        </tr>
                        <tr>
                          <th>DUNS (if applicable)</th>
                          <td>{appData.EnitityOwnerDuns}</td>
                          <td>{appData.ParentCompanyDuns}</td>
                        </tr>
                        <tr>
                          <th>Email</th>
                          <td>{appData.EnitityOwnerEmail}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <th>Phone Number</th>
                          <td>{appData.EnitityOwnerPhoneNumber}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <th>Address</th>
                          <td>{appData.EnitityOwnerAddressLine1}, {appData.EnitityOwnerAddressLine2 && <>{appData.EnitityOwnerAddressLine2}, </>}{appData.EnitityOwnerState}, {appData.EnitityOwnerCity}, {appData.EnitityOwnerZip}</td>
                          <td>{appData.ParentCompanyAddressLine1}, {appData.ParentCompanyAddressLine2 && <>{appData.ParentCompanyAddressLine2}, </>}{appData.ParentCompanyState}, {appData.ParentCompanyCity}, {appData.ParentCompanyZip}</td>
                        </tr>
                        <tr>
                          <th>Comments</th>
                          <td colSpan={2}>{appData.EntityOwnershipComments}</td>
                        </tr>
                      </tbody>
                    </table>

                    <div className='mt-4'><b>Ownership Information</b></div>
                    <table className="table table-bordered mt-3">
                      <thead>
                        <tr>
                          <th colSpan={4}>Entity Investor(s)</th>
                          <th colSpan={2}>Ultimate Parent Company</th>
                        </tr>
                        <tr>
                          <th>Company or Individual Full Legal Name</th>
                          <th>Title (for Individuals)</th>
                          <th>Percentage owned or (if less than 20%, add other significant influence factors in the comments)  </th>
                          <th>Comments</th>
                          <th>Company or Individual Full Legal Name that is the Ultimate Parent of the Beneficial Owner with Significant Influence in the first column of this table.</th>
                          <th>Comments</th>
                        </tr>
                      </thead>
                      <tbody>
                        {beneficialOwnerInformation.length > 0 && beneficialOwnerInformation.map((item, index) => {
                          return(
                            <tr>
                              <td className="align-middle">{item.boLegalName}</td>
                              <td className="align-middle">{item.boTitle}</td>
                              <td className="align-middle">{item.boPercentageOwned} %</td>
                              <td className="align-middle">{item.boComments}</td>
                              <td className="align-middle">{item.parentCompanyLegalName}</td>
                              <td className="align-middle">{item.parentCompanyComments}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </section>
                </div>
              </section>
              {appData.Industry === 'Banks / Credit Union / CDFI' ? <section className='bdr-top'>
                <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Bank Specific Details</b></div>
                <div className="sub-group">
                  <div>
                    <div><b>Ownership</b></div>
                    <span className='label'>Names of owner(s) & percentage(s) of ownership:</span><div className='border p-2 mb-3 bg-white'>{appData.OwnersNameAndPercentageOwnershipBanks ? <span dangerouslySetInnerHTML={{__html: appData.OwnersNameAndPercentageOwnershipBanks}}/> : <>&nbsp;</>}</div>
                    <span className='label'>Name of all employee(s) who are related to any owner and name of the related owner(s). Please mention the relationship between such employee(s) and owner(s):</span><div className='border p-2 bg-white'>{appData.OwnersRelatedAndRelationshipBanks ? <span dangerouslySetInnerHTML={{__html: appData.OwnersRelatedAndRelationshipBanks}}/> : <>&nbsp;</>}</div>
                  </div>
                  <section className='bdr-top mt-3'>
                    <div><b>CFO</b></div>
                    <Row>
                      <Col><span className='label'>Name:</span><br/>{appData.CfoFullName}</Col>
                      <Col><span className='label'>Address:</span><br/>{appData.CfoAddress}</Col>
                      <Col>{userPrivilegedPIIInfo && <><span className='label'>Email:</span><br/>{appData.CfoEmail}</>}</Col>
                      <Col>{userPrivilegedPIIInfo && <><span className='label'>Phone Number:</span><br/>{appData.CfoPhoneNumber}</>}</Col>
                    </Row>
                  </section>
                  <section className='bdr-top'>
                    <Row>
                      <Col>
                        <span className='label'>Address on File with IRS</span><br/>
                        {appData.CfoAddressLine1onFileWithIRS && <>{appData.CfoAddressLine1onFileWithIRS}, </>} {appData.CfoAddressLine2onFileWithIRS && <>{appData.CfoAddressLine2onFileWithIRS}, </>}{appData.CfoState && <>{appData.CfoState}, </>}{appData.CfoCity && <>{appData.CfoCity}, </>}{appData.CfoZip && <>{appData.CfoZip}</>}
                      </Col>
                    </Row>
                  </section>
                  <section className='bdr-top mt-3'>
                    <div><b>Identification</b></div>
                    <Row>
                      <Col><span className='label'>Bank RSSD ID / Credit Union Charter Number / CDFI Certification Number:</span><br/>{appData.IdrssdId}</Col>
                    </Row>
                  </section>
                  {/* <section className='bdr-top mt-3'>
                    <div><b>Gross Receipts Decline</b></div>
                    <Row>
                      <Col><span className='label'>Did the business experience a significant decline in gross receipts (50%) in any quarter during 2020, as compared to the same quarter in 2019?:</span><br/>{appData.GRDeclineAnyQuarter}</Col>
                      <Col><span className='label'>Did the business experience a significant decline in gross receipts (20%) in Q4 of 2020 or any quarter during 2021, as compared to the same quarter in 2019?:</span><br/>{appData.GRDeclineQ4}</Col>
                    </Row>
                  </section> */}
                </div>
              </section> : <>{/* <section className='bdr-top'>
                <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Business Owner</b></div>
                <div className="sub-group">
                  <Row className='mb-3'>
                    <Col><span className='label'>Name:</span><br/>{appData.BusinessOwnerFullName}</Col>
                    <Col><span className='label'>Address:</span><br/>{appData.BusinessOwnerAddress}</Col>
                    <Col><span className='label'>Email:</span><br/>{appData.BusinessOwnerEmail}</Col>
                    <Col><span className='label'>Phone Number:</span><br/>{appData.BusinessOwnerPhoneNumber}</Col>
                  </Row>
                  <section className='bdr-top'>
                    <Row>
                      <Col>
                        <span className='label'>Address on File with IRS</span><br/>
                        {appData.BusinessOwnerAddressLine1onFileWithIRS && <>{appData.BusinessOwnerAddressLine1onFileWithIRS}, </>} {appData.BusinessOwnerAddressLine2onFileWithIRS && <>{appData.BusinessOwnerAddressLine2onFileWithIRS}, </>}{appData.BusinessOwnerState && <>{appData.BusinessOwnerState}, </>}{appData.BusinessOwnerCity && <>{appData.BusinessOwnerCity}, </>}{appData.BusinessOwnerZip && <>{appData.BusinessOwnerZip}</>}
                      </Col>
                    </Row>
                  </section>
                  <section className='bdr-top mt-3'>
                    <span className='label'>Names of owner(s) & percentage(s) of ownership:</span><div className='border p-2 mb-3 bg-white'>{appData.OwnersNameAndPercentageOwnership ? <span dangerouslySetInnerHTML={{__html: appData.OwnersNameAndPercentageOwnership}}/> : <>&nbsp;</>}</div>
                    <span className='label'>Name of all employee(s) who are related to any owner and name of the related owner(s). Please mention the relationship between such employee(s) and owner(s):</span><div className='border p-2 bg-white'>{appData.OwnersRelatedAndRelationship ? <span dangerouslySetInnerHTML={{__html: appData.OwnersRelatedAndRelationship}}/> : <>&nbsp;</>}</div>
                  </section>
                </div>
              </section> */}</>}
              {/* <section className='bdr-top'>
                <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Other Details</b></div>
                <div className="sub-group">
                  <ul className='ps-4'>
                    <li>
                      <Row>
                        <Col xs={8}><span className='label'>Already applied for ERC for 2020/2021:</span></Col>
                        <Col>{appData.AlreadyAppliedForERC}{appData.AlreadyAppliedForERCExplaination && <>. <span dangerouslySetInnerHTML={{__html: appData.AlreadyAppliedForERCExplaination}}/></>}</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col xs={8}><span className='label'>Company previously claimed following incentive or credit:</span></Col>
                        <Col>
                          {dataClaimedCredits && dataClaimedCredits.map((items,index) => <span key={index}>{items}{index+1 !== dataClaimedCredits.length && <>,<br/></>}</span>)}
                        </Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col xs={8}><span className='label'>Company accepted funds from either a PPP or PPP2:</span></Col>
                        <Col>{appData.TakenPPP}</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col xs={8}><span className='label'>Is your business a sole proprietorship:</span></Col>
                        <Col>{appData.IsSoleProp}</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col xs={8}><span className='label'>Is your company a government, quasi-government, public sector entity or a taxing authority:</span></Col>
                        <Col>{appData.InRestrictedIndustry}</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col xs={8}><span className='label'>Does your company have business connections in any of the following embargoed regions/countries: Crimea, Cuba, Iran, North Korea, Russia, Syria or either the Luhansk or Donetsk regions of Ukraine:</span></Col>
                        <Col>{appData.InRestrictedRegion}</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col xs={8}><span className='label'>Does your company have a business bank account with a U.S. registered bank (including foreign banks with registered U.S. branches):</span></Col>
                        <Col>{appData.HaveUSBank}</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col xs={8}><span className='label'>Does a private equity fund, venture capital fund or hedge fund have a direct or indirect ownership stake or investment in your company:</span></Col>
                        <Col>{appData.IsOwnedbyFund}</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col xs={8}><span className='label'>Does another company own at least 50% by vote or value of your company:</span></Col>
                        <Col>{appData.IsOwnedbyOtherBiz}</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col xs={8}><span className='label'>Does your company own at least 50% by vote or value of one or more other companies:</span></Col>
                        <Col>{appData.IsOwnsOtherBiz}</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col xs={8}><span className='label'>Does the same individual or group of five or fewer individuals, estates or trusts own at least 80% of your company AND one or more other companies; and is the individual or group's common ownership of one or more of those companies at least 50%:</span></Col>
                        <Col>{appData.IsAffiliatedEntity}</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col xs={8}><span className='label'>Does your company treat itself as part of an affiliated service group for purposes of employee benefit plans:</span></Col>
                        <Col>{appData.HasAffiliatedBenefits}</Col>
                      </Row>
                    </li>
                  </ul>
                  <section className='bdr-top mt-3'><span className='label'>List of Legal Company Name and EIN Number of all other affiliated companies:</span><div className='border p-2 bg-white'>{appData.RelatedEntities ? <span dangerouslySetInnerHTML={{__html: appData.RelatedEntities}}/> : <>&nbsp;</>}</div></section>
                </div>
              </section> */}
            </Container>
          </section>
              
          {/* FEZ Information */}
          <section className='bg-gray'>
            <Container fluid>
              <h6>FEZ Information</h6>
              {appData.Industry === 'Banks / Credit Union / CDFI' ? <></> : <>
                {userPrivilegedViewKyc &&
                  <section>
                    <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Verification report</b></div>
                    <div className="sub-group">
                      <Row className='mb-3'>
                        <Col>
                          {appData.DriversLicense && <><button className='verification' onClick={() => showReport('DriversLicense')}><span>ID Verification</span><img src={require('./../assets/images/id-verification-icon.png')} alt="ID icon" /></button>
                          <Modal className='modal-medium show-report' show={showReportModal} onHide={() => setShowReportModal(false)} backdrop="static" keyboard={false} centered>
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                              <div>{reportData}</div>
                            </Modal.Body>
                          </Modal>
                          </>}
                        </Col>
                        <Col>{appData.OpenCorporateDataOfficer && <button className='verification' onClick={() => showReport('OpenCorporateDataOfficer')}><span>Business Owner Verification</span><img src={require('./../assets/images/user-icon-2.png')} alt="Owner icon" /></button>}</Col>
                        <Col>{appData.OpenCorporateDataCompany && <button className='verification' onClick={() => showReport('OpenCorporateDataCompany')}><span>Business  Verification</span><img src={require('./../assets/images/business-icon.png')} alt="Business icon" /></button>}</Col>
                      </Row>
                    </div>
                  </section>
                }
              </>}
              <section className={(appData.Industry !== 'Banks / Credit Union / CDFI' && userPrivilegedViewKyc) ? 'bdr-top' : undefined}>
                <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Authorized Signer</b></div>
                <div className="sub-group">
                  <Row className='mb-3'>
                    <Col><span className='label'>Name:</span><br/>{appData.AuthorizedSignerFullName}</Col>
                    <Col><span className='label'>Address:</span><br/>{appData.AuthorizedSignerAddress}</Col>
                    <Col>{userPrivilegedPIIInfo && <><span className='label'>Email:</span><br/>{appData.AuthorizedSignerEmail}</>}</Col>
                    <Col>{userPrivilegedPIIInfo && <><span className='label'>Phone Number:</span><br/>{appData.AuthorizedSignerPhoneNumber}</>}</Col>
                  </Row>
                  <section className='bdr-top'>
                    <Row>
                      <Col>
                        <span className='label'>Address on File with IRS</span><br/>
                        {appData.AddressLine1onFileWithIRS && <>{appData.AddressLine1onFileWithIRS}, </>} {appData.AddressLine2onFileWithIRS && <>{appData.AddressLine2onFileWithIRS}, </>}{appData.AuthorizedSignerState && <>{appData.AuthorizedSignerState}, </>}{appData.AuthorizedSignerCity && <>{appData.AuthorizedSignerCity}, </>}{appData.AuthorizedSignerZip && <>{appData.AuthorizedSignerZip}</>}
                      </Col>
                    </Row>
                  </section>
                </div>
              </section>
              <section className='bdr-top'>
                <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Employee Details</b></div>
                <div className="sub-group">
                  <Row>
                    <Col className='bdr-right'>
                      <ul className='ps-4'>
                        {/* <li>
                          <Row>
                            <Col xs={8}><span className='label'>Company or its partners has 80% or more ownership in any other businesses:</span></Col>
                            <Col>{appData.MoreThan80PerOwnership}</Col>
                          </Row>
                        </li> */}
                        <li>
                          <Row>
                            <Col xs={8}><span className='label'>Has taken any of the following credits? (Employee Retention Credit (ERC), Disaster Employee Retention Credit (DZ), Work Opportunity Tax Credit (WOTC), Indian Employment Credit (IEC)):</span></Col>
                            <Col>{appData.TakenCredits}</Col>
                          </Row>
                        </li>
                      </ul>
                    </Col>
                    <Col>
                      <b>Timeframe:</b>
                      <table className='table table-striped table-borderless mb-0'>
                        <thead>
                          <tr>
                            <th>From</th>
                            <th>To</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>{appData.CreditsTimeframeFrom}</th>
                            <td>{appData.CreditsTimeframeTo}</td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <section className='bdr-top mt-3'>
                    <div><b>Employee Data</b></div>
                    {appData.EmployeeData && <div>
                      {appData.EmployeeData}<br/>
                      <button type="button" className='view-file' title={appData.EmployeeData} onClick={() => showFile('EmployeeData')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.EmployeeData} onClick={() => downloadFile('EmployeeData')}><i className="fas fa-file-download"></i></button>
                    </div>}
                  </section>
                  <section className='bdr-top mt-3'>
                    <div><b>Processed Employee Data</b></div>
                    {appData.ProcessedEmployeeData && <div>
                      {appData.ProcessedEmployeeData}<br/>
                      <button type="button" className='view-file' title={appData.ProcessedEmployeeData} onClick={() => showFile('ProcessedEmployeeData')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.ProcessedEmployeeData} onClick={() => downloadFile('ProcessedEmployeeData')}><i className="fas fa-file-download"></i></button>
                    </div>}
                  </section>
                </div>
              </section>
            </Container>
          </section>
          {/* Onboarding Questionnaire */}
          {/* {appData.Industry === 'Banks / Credit Union / CDFI' && userPrivilegedPIIInfo && <section className='bg-gray'>
            <Container fluid>
              <h6>Onboarding Questionnaire</h6>
              <div><b>ERC Onboarding Questionnaire</b></div>
              {appData.ERCOnboardingQuestionnaireUpload && <div>
                {appData.ERCOnboardingQuestionnaireUpload}<br/>
                <button type="button" className='view-file' title={appData.ERCOnboardingQuestionnaireUpload} onClick={() => showFile('ERCOnboardingQuestionnaireUpload')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.ERCOnboardingQuestionnaireUpload} onClick={() => downloadFile('ERCOnboardingQuestionnaireUpload')}><i className="fas fa-file-download"></i></button>
              </div>}
            </Container>
          </section>} */}
          {/* Engagement Agreement */}
          <section className='bg-gray'>
            <Container fluid>
              <h6>Engagement Agreement</h6>
              <div><b>Signed Engagement Agreement</b></div>
              {appData.SignedEngagementAgreement && <div>
                {appData.SignedEngagementAgreement}<br/>
                <button type="button" className='view-file' title={appData.SignedEngagementAgreement} onClick={() => showFile('SignedEngagementAgreement')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.SignedEngagementAgreement} onClick={() => downloadFile('SignedEngagementAgreement')}><i className="fas fa-file-download"></i></button>
              </div>}
            </Container>
          </section>
          {/* Additional Documents from Customer */}
          <section className='bg-gray'>
            <Container fluid>
              <h6>Additional Documents from Customer</h6>
              {appData.Doc01 ? <Row>
                {['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49','50'].map((items, index) => {
                  return(
                    <>{appData['Doc'+items] && <Col sm={3} lg={2} className='my-3 text-center'>
                      <div key={index}><button className='view-file bg-transparent' title={appData['Doc'+items]} onClick={() => showFile('Doc'+items)}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file bg-transparent' title={appData['Doc'+items]} onClick={() => downloadFile('Doc'+items)}><i className="fas fa-file-download"></i></button></div>
                      <div className='txt-overflow' title={appData['Doc'+items]}>{appData['Doc'+items]}</div>
                      {appDataTimestamp['Doc'+items] && <div className='txt-overflow'><small>{appDataTimestamp['Doc'+items]}</small></div>}
                      </Col>
                    }</>
                  )
                })}
              </Row> : <>Null</>}
            </Container>
          </section>
          {/* appData.EngagementAgreementWSAccepted && */ <>
            {/* Final Form*/}
            {userPrivilegedPIIInfo && <section className='bg-gray'>
              <Container fluid>
                <div className='border-bottom pb-3 mb-3'>
                  <h6>Final Form from Tax Preparer</h6>
                  <table className='table table-docs table-upload table-borderless mt-2'>
                    <thead>
                      <tr>
                        <th>Doc 01</th>
                        <th>Doc 02</th>
                        <th>Doc 03</th>
                        <th>Doc 04</th>
                        <th>Tax Preparer Comments</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {!appData.FinalForm941xSentToCustomer && <div className="position-relative d-inline-block">
                            <Form.Label htmlFor="FinalFormDoc1" className='upload upload-default'>
                              <span className="txt-upload">Upload</span>
                            </Form.Label>
                            <Form.Control id="FinalFormDoc1" name="FinalFormDoc1" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedUpdateDocument} onChange={(e) => handleUpload('final_form_941x', e)} />
                          </div>}
                          {appData.FinalFormDoc1 && appData.FinalFormDoc1}<br/>
                          {appData.FinalFormDoc1 && <><button type="button" className='view-file' title={appData.FinalFormDoc1} onClick={() => showFile('FinalFormDoc1')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.FinalFormDoc1} onClick={() => downloadFile('FinalFormDoc1')}><i className="fas fa-file-download"></i></button></>}
                        </td>
                        <td>
                          {!appData.FinalForm941xSentToCustomer && <div className="position-relative d-inline-block">
                            <Form.Label htmlFor="FinalFormDoc2" className='upload upload-default'>
                              <span className="txt-upload">Upload</span>
                            </Form.Label>
                            <Form.Control id="FinalFormDoc2" name="FinalFormDoc2" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedUpdateDocument} onChange={(e) => handleUpload('final_form_941x', e)} />
                          </div>}
                          {appData.FinalFormDoc2 && appData.FinalFormDoc2}<br/>
                          {appData.FinalFormDoc2 && <><button type="button" className='view-file' title={appData.FinalFormDoc2} onClick={() => showFile('FinalFormDoc2')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.FinalFormDoc2} onClick={() => downloadFile('FinalFormDoc2')}><i className="fas fa-file-download"></i></button></>}
                        </td>
                        <td>
                          {!appData.FinalForm941xSentToCustomer && <div className="position-relative d-inline-block">
                            <Form.Label htmlFor="FinalFormDoc3" className='upload upload-default'>
                              <span className="txt-upload">Upload</span>
                            </Form.Label>
                            <Form.Control id="FinalFormDoc3" name="FinalFormDoc3" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedUpdateDocument} onChange={(e) => handleUpload('final_form_941x', e)} />
                          </div>}
                          {appData.FinalFormDoc3 && appData.FinalFormDoc3}<br/>
                          {appData.FinalFormDoc3 && <><button type="button" className='view-file' title={appData.FinalFormDoc3} onClick={() => showFile('FinalFormDoc3')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.FinalFormDoc3} onClick={() => downloadFile('FinalFormDoc3')}><i className="fas fa-file-download"></i></button></>}
                        </td>
                        <td>
                          {!appData.FinalForm941xSentToCustomer && <div className="position-relative d-inline-block">
                            <Form.Label htmlFor="FinalFormDoc4" className='upload upload-default'>
                              <span className="txt-upload">Upload</span>
                            </Form.Label>
                            <Form.Control id="FinalFormDoc4" name="FinalFormDoc4" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedUpdateDocument} onChange={(e) => handleUpload('final_form_941x', e)} />
                          </div>}
                          {appData.FinalFormDoc4 && appData.FinalFormDoc4}<br/>
                          {appData.FinalFormDoc4 && <><button type="button" className='view-file' title={appData.FinalFormDoc4} onClick={() => showFile('FinalFormDoc4')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.FinalFormDoc4} onClick={() => downloadFile('FinalFormDoc4')}><i className="fas fa-file-download"></i></button></>}
                        </td>
                        <td>
                          {!appData.FinalForm941xSentToCustomer && <div className="position-relative d-inline-block">
                              <Form.Label htmlFor="TaxPreparerComments" className='upload upload-default'>
                                <span className="txt-upload">Upload</span>
                              </Form.Label>
                              <Form.Control id="TaxPreparerComments" name="TaxPreparerComments" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedUpdateDocument} onChange={(e) => handleUpload('final_form_941x', e)} />
                            </div>}
                            {appData.TaxPreparerComments && appData.TaxPreparerComments}<br/>
                            {appData.TaxPreparerComments && <><button type="button" className='view-file' title={appData.TaxPreparerComments} onClick={() => showFile('TaxPreparerComments')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.TaxPreparerComments} onClick={() => downloadFile('TaxPreparerComments')}><i className="fas fa-file-download"></i></button></>}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                  {!appData.FinalForm941xSentToCustomer && <div className='mt-3'><Button variant="primary" type="submit" disabled={(appData.Submitted && (appData.FinalFormDoc1 || appData.FinalFormDoc2 || appData.FinalFormDoc3 || appData.FinalFormDoc4)) ? false : true} onClick={(e) => handleSetData('final_form_941x', 'FinalForm941xSentToCustomer', true, e)}>Send to Customer</Button></div>}
                  {(appData.FinalForm941xSentToCustomer/*  && !appData.SignedFinalForm941x */) && <div className='mt-4'><span className='label'>Status:</span> Final Form sent to customer</div>}
                  {/* {appData.SignedFinalForm941x && <div className='d-flex'>
                    <div className='mt-4'><span className='label'>Status:</span> Final Form <b>signed</b> by customer</div>
                    <div>
                      {appData.SignedFinalForm941x}<br/>
                      <button type="button" className='view-file' title={appData.SignedFinalForm941x} onClick={() => showFile('SignedFinalForm941x')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.SignedFinalForm941x} onClick={() => downloadFile('SignedFinalForm941x')}><i className="fas fa-file-download"></i></button>
                    </div>
                  </div>} */}
                </div>
                <h6>Additional Documents from Tax Preparer</h6>
                <div className='table-wrapper'>
                  <table className='table table-docs table-upload table-borderless mt-2'>
                    <thead>
                      <tr>
                        <th>Doc 01</th>
                        <th>Doc 02</th>
                        <th>Doc 03</th>
                        <th>Doc 04</th>
                        <th>Doc 05</th>
                        <th>Doc 06</th>
                        <th>Doc 07</th>
                        <th>Doc 08</th>
                        <th>Doc 09</th>
                        <th>Doc 10</th>
                        <th>Doc 11</th>
                        <th>Doc 12</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {Array.apply(null, {length: 12}).map((item, index) => {
                          let count = index +1;
                          return(
                            <td>
                              {<div className="position-relative d-inline-block">
                                <Form.Label htmlFor={"FinalForm941xAddDoc"+count} className='upload upload-default'>
                                  <span className="txt-upload">Upload</span>
                                </Form.Label>
                                <Form.Control id={"FinalForm941xAddDoc"+count} name={"FinalForm941xAddDoc"+count} type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedUpdateDocument} onChange={(e) => handleUpload('final_form_941x', e)} />
                              </div>}
                              {appData[`FinalForm941xAddDoc${count}`] && appData[`FinalForm941xAddDoc${count}`]}<br/>
                              {appData[`FinalForm941xAddDoc${count}`] && <><button type="button" className='view-file' title={appData[`FinalForm941xAddDoc${count}`]} onClick={() => showFile(`FinalForm941xAddDoc${count}`)}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData[`FinalForm941xAddDoc${count}`]} onClick={() => downloadFile(`FinalForm941xAddDoc${count}`)}><i className="fas fa-file-download"></i></button></>}
                            </td>
                          )
                        })}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Container>
            </section>}

            <div className='mt-4 mb-5'>
              <Container fluid>
                {(appData.TermsOfService && appData.PrivacyPolicy) && <p>* The user agrees to all Terms and Conditions and also the Privacy policy.</p>}
                {(appData.CertificationOfData) && <p>* The user certifies that all the information submitted in the form is correct.</p>}
                <Button variant="transparent" onClick={() => navigate('/')}>Back</Button>
                {/* <Button variant="primary" className='ms-3' onClick={markAsCompleted} disabled={!denyMarkCompleted}>Mark as Completed</Button>
                {userPrivilegedDownload && <Button variant="primary" className='ms-3' onClick={downloadAll}>Download All</Button>} */}
                
                {/* {userPrivilegedMessaging &&
                  <ClientChat mToken={mToken} applicationID={applicationID} />
                } */}
              </Container>
            </div>
          </>}
        </>}

        <Modal className='show-picture' show={showFileModal} onHide={() => {setShowFileModal(false); setFile(''); setExcelFile(null);}} backdrop="static" keyboard={false} centered>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            {excelFile && <div className='excelTable-wrap'><OutTable data={excelFile.rows} columns={excelFile.cols} tableClassName="table bordered excelTable mb-0 bg-white" tableHeaderRowClass="heading" /></div>}
            {file && <embed src={file} width={file.startsWith("data:image") ? '' : '100%'} height="800" />}
          </Modal.Body>
        </Modal>

        {userPrivilegedReadTPCommunicationLog && <>
          <section>
            <Container fluid>
              <h6>Customer Communication Log</h6>
              <div className='message-table'>
                <table className="table table-log table-striped table-borderless mb-0">
                  <thead>
                    <tr>
                      <th>Sender</th>
                      <th>Message</th>
                      <th>Sent at</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tpCommunicationLog && tpCommunicationLog.map((item, index) => {
                      return(
                        <tr key={index}>
                          <td>{item.sender === 'cpa' ? 'tax preparer' : item.sender}</td>
                          <td>{item.message_text}</td>
                          <td>{item.created_at}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Container>
          </section>
        </>}

        {userPrivilegedReadApplicationHistory && <>
          <section>
            <Container fluid>
              <h6>Audit Log</h6>
              <table className="table table-log table-striped table-borderless mb-0">
                <thead>
                  <tr>
                    <th>Entity Name</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Updated by</th>
                    <th>Updated at</th>
                  </tr>
                </thead>
                <tbody>
                    {auditData && auditData.sort((a, b) => b.at.localeCompare(a.at)).map((item, index) => {
                      return(
                        <tr key={index}>
                          <td>{item.entity}</td>
                          <td><div dangerouslySetInnerHTML={{__html: item.from}} /></td>
                          <td><div dangerouslySetInnerHTML={{__html: item.to}} /></td>
                          <td>{item.by}</td>
                          <td>{item.at}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Container>
          </section>
        </>}
      </main>
    </>
  );
}

export default ApplicationDetails;
