import React, {useState, useEffect} from 'react';
import { Row, Col, Button, Modal, Form} from 'react-bootstrap';
import axios from "axios";
import AppDataGrid from '../components/AppDataGrid';

const serverConfig = require("../config/server.js");

const ApplicationList = ({isUserPrivileged, handleLogout}) => {

  const mToken = sessionStorage.getItem('mToken');

  const [showInvitePopup, setShowInvitePopup] = useState(false);
  const handleCloseInvitePopup = () => {
    setInputs({});
    setShowInvitePopup(false);
  }
  const handleShowInvitePopup = () => setShowInvitePopup(true);
  
  const [inviteSentMessage, setInviteSentMessage] = useState('');
  const [showAlreadySentPopup, setShowAlreadySentPopup] = useState(false);
  const handleCloseAlreadySentPopup = () => setShowAlreadySentPopup(false);
  const handleShowAlreadySentPopup = () => setShowAlreadySentPopup(true);

  const [applicationCount, setApplicationCount] = useState({});
  
  const [showApplications, setShowApplications] = useState('all');

  const [sendInviteDisabled, setSendInviteDisabled] = useState(true);
  
  const [inputs, setInputs] = useState({});
  
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  useEffect(() => {
    //Refresh app data grid on load of the page - sometimes app data grid doesn't refresh
    setShowApplications('');
    setTimeout(() => {
      setShowApplications('all');
    }, 100);
  }, []);

  useEffect(() => {
    console.log('mToken', mToken);
    console.log(sessionStorage.getItem('mToken'));
    
    if(mToken !== null && mToken !== undefined){
      console.log('mToken', mToken);
      /* check_user_privilege - create_invite */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'create_invite'})
      .then(response=>{
        console.log("check_user_privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 0){
            setSendInviteDisabled(true);
          } else if(response.data.is_privileged === 1){
            setSendInviteDisabled(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
      /* Application Counts */
      axios.post(serverConfig.api_base_url + "get_app_count",{token:mToken, privilege:'create_invite'})
      .then(response=>{
        console.log("get_app_count response: ", response);
        if (response.data.status === 200) {
          setApplicationCount(JSON.parse(response.data.output));
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      } )
      .catch(err=>console.log("error is",err));
    }
  }, [mToken, showApplications]);

  const handleSendInvite = (e) => {
    e.preventDefault();
    var formdata = {
      token:mToken, ...inputs, force_flag:0
    }
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "create_invite",formdata)
    .then(response=>{
      console.log("Send Invite response:", response);
      console.log(response.data.ref_id);
      if(response.data.status === 200){
        handleCloseInvitePopup();
      } else if(response.data.status === 210){
        setInviteSentMessage(response.data.message);
        handleShowAlreadySentPopup();
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleProceedSendInvite = (e) => {
    e.preventDefault();
    var formdata = {
      token:mToken, ...inputs, force_flag:1
    }
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "create_invite",formdata)
    .then(response=>{
      console.log("Proceed with Send Invite response:", response);
      console.log(response.data.ref_id);
      if(response.data.status === 200){
        handleCloseAlreadySentPopup();
        handleCloseInvitePopup();
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const refreshAppList =(e) => {
    //e.preventDefault();
    axios.post(serverConfig.api_base_url + "get_app_list",{filter_check:'all', token:mToken})
    .then(response=>{
      console.log("get_app_list response:", response);
      console.log("get_app_list response:", response.data.status);
      if(response.data.status === 200){
        setShowApplications('');
        setTimeout(() => {
          setShowApplications('all');
        }, 100);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  return (
    <>
      {/* <Header />
      <main>
        <Container fluid> */}
          <h1>Empowerment Zone Tax Credit Applications</h1>
          <section>
            <Row className='application-stats'>
              {isUserPrivileged ? (<>
                <Col>
                  <div className='inner' onClick={() => setShowApplications('all')}>
                    <h4>Total Applications</h4>
                    <div className='count-wrap'>
                      <span className='count'><span>{'total' in applicationCount ? applicationCount.total : '0'}</span>Nos.</span>
                      <span><img src={require('./../assets/images/application-icon.png')} alt="Application icon" /></span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className='inner' onClick={() => setShowApplications('unassigned')}>
                    <h4>Unassigned</h4>
                    <div className='count-wrap'>
                      <span className='count'><span>{'unassigned' in applicationCount ? applicationCount.unassigned : '0'}</span>Nos.</span>
                      <span><img src={require('./../assets/images/unassigned-application-icon.png')} alt="New Applications icon" /></span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className='inner' onClick={() => setShowApplications('under_cpa_review')}>
                    <h4>Under Review by Tax Preparer</h4>
                    <div className='count-wrap'>
                      <span className='count'><span>{'under_cpa_review' in applicationCount ? applicationCount.under_cpa_review : '0'}</span>Nos.</span>
                      <span><img src={require('./../assets/images/new-applications-icon.png')} alt="New Applications icon" /></span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className='inner' onClick={() => setShowApplications('completed')}>
                    <h4>Completed</h4>
                    <div className='count-wrap'>
                      <span className='count'><span>{'completed' in applicationCount ? applicationCount.completed : '0'}</span>Nos.</span>
                      <span><img src={require('./../assets/images/approved-application-icon.png')} alt="Approved Application icon" /></span>
                    </div>
                  </div>
                </Col>
              </>) : (<>
                <Col>
                <div className='inner' onClick={() => setShowApplications('all')}>
                  <h4>Applications Assigned to Me</h4>
                  <div className='count-wrap'>
                    <span className='count'><span>{'total' in applicationCount ? applicationCount.total : '0'}</span>Nos.</span>
                    <span><img src={require('./../assets/images/application-icon.png')} alt="Application icon" /></span>
                  </div>
                </div>
              </Col>
              <Col>
                <div className='inner' onClick={() => setShowApplications('under_review')}>
                  <h4>Applications Under Review</h4>
                  <div className='count-wrap'>
                    <span className='count'><span>{'under_review' in applicationCount ? applicationCount.under_review : '0'}</span>Nos.</span>
                    <span><img src={require('./../assets/images/new-applications-icon.png')} alt="New Applications icon" /></span>
                  </div>
                </div>
              </Col>
              <Col>
                <div className='inner' onClick={() => setShowApplications('completed')}>
                  <h4>Applications Completed</h4>
                  <div className='count-wrap'>
                    <span className='count'><span>{'completed' in applicationCount ? applicationCount.completed : '0'}</span>Nos.</span>
                    <span><img src={require('./../assets/images/approved-application-icon.png')} alt="Approved Application icon" /></span>
                  </div>
                </div>
              </Col>
              </>)}
            </Row>
          </section>
          <section>
            <Button variant="transparent" className='btn-send-invite' onClick={handleShowInvitePopup} disabled={sendInviteDisabled}>Send Invite to New User</Button>
            <Modal show={showInvitePopup} onHide={handleCloseInvitePopup} backdrop="static"keyboard={false} centered>
              <Form onSubmit={handleSendInvite}>
                <Modal.Header closeButton>
                  <Modal.Title>New User Invite</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" placeholder="Name" name="name" value={inputs.name || ""} onChange={handleChange} required />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control type="email" placeholder="Email" name="app_email_id" value={inputs.app_email_id || ""} onChange={handleChange} required />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" placeholder="Mobile No." name="phone_number" value={inputs.phone_number || ""} onChange={handleChange} required />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="transparent" onClick={handleCloseInvitePopup}>Cancel</Button>
                  <Button variant="primary" type="submit" className='ms-3'><img src={require('./../assets/images/sent-invite-icon-white.png')} alt="Sent Invite Icon" className='me-2' />Send Invite</Button>
                </Modal.Footer>
              </Form>
            </Modal>
            <Modal show={showAlreadySentPopup} onHide={handleCloseAlreadySentPopup} backdrop="static"keyboard={false} centered className='content-text'>
              <Modal.Body>
                <p>{inviteSentMessage}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="transparent" onClick={handleCloseAlreadySentPopup}>No</Button>
                <Button variant="primary" className='ms-3' onClick={handleProceedSendInvite}>Yes</Button>
              </Modal.Footer>
            </Modal>
          </section>
          <section>
            <div className='datatable datatable-app-list'>
              <h2>Total Applications <button className='ms-3' onClick={refreshAppList}><i className="fas fa-sync"></i></button></h2>
              {/* onClick={() => setShowApplications('all')} */}
              <AppDataGrid show={showApplications} refreshAppList={refreshAppList} token={mToken} isUserPrivileged={isUserPrivileged} handleLogout={handleLogout} />
            </div>
          </section>
        {/* </Container>
      </main> */}
    </>
  );
}

export default ApplicationList;
